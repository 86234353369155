import { ICpConnectRepository } from '@modules/cpConnect/application/ports/cpConnect.repository.port';
import { CpConnectResponse, GetCpConnectQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { CpConnectEntityMapper } from '../../outbound/ports/cpConnect.mapper';
export class CpConnectRepositoryImpl implements ICpConnectRepository {
  async getAllList(limit?: number): ICpConnectRepository.output {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetCpConnectQueryDocument,
        variables: {
          data: {
            limit: limit ? limit : null
          }
        }
      });
      const cpConnectList = data.getCpConnect.map((cpConnect: CpConnectResponse) => CpConnectEntityMapper.toDomain(cpConnect));
      return right(cpConnectList);
    } catch (error: any) {
      return left(error);
    }
  }
}