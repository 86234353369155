import { IGetHomeCampaignUsecase } from '@modules/home/domain/ports/getCampaign.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { IHomeCampaignRepository } from '../ports/campaign.repository.port';
export class GetHomeCampaignUsecase implements IGetHomeCampaignUsecase {
  constructor(private readonly homeCampaignRepo: IHomeCampaignRepository) {}
  async handle(): IGetHomeCampaignUsecase.getCampaignOutput {
    const result = await this.homeCampaignRepo.getCampaign();
    const value = result.value;
    if (result.isLeft()) return left([]);
    return right(value);
  }
}