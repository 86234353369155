import { IGameBanner } from '@modules/home/domain/models/gameBanner.model';
import { BannerResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
export class GameBannerEntityMapper {
  public static toDomain({
    id,
    title,
    bannerDesktop,
    bannerMobile,
    bannerIos,
    linkOutUrl
  }: BannerResponse): IGameBanner {
    return {
      id,
      title,
      bannerDesktop,
      bannerMobile,
      bannerIos,
      linkOutUrl: getLinkoutPrefixHttps(linkOutUrl)
    };
  }
}