import { motion } from 'framer-motion';
import styled from 'styled-components';
export const Container = styled(motion.div).withConfig({
  displayName: "HomePopupstyle__Container",
  componentId: "sc-n0f1w2-0"
})(["", ""], {
  "position": "fixed",
  "left": "0px",
  "right": "0px",
  "top": "0px",
  "zIndex": "49",
  "display": "flex",
  "minHeight": "100dvh",
  "alignItems": "center",
  "justifyContent": "center"
});
export const WrapperImage = styled.div.withConfig({
  displayName: "HomePopupstyle__WrapperImage",
  componentId: "sc-n0f1w2-1"
})(["", " @media (max-width:", "){", "}"], {
  "position": "relative",
  "zIndex": "48",
  "display": "flex",
  "height": "421px",
  "width": "730px",
  "alignItems": "center",
  "justifyContent": "center"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "100%",
  "width": "100%",
  "flexDirection": "column",
  "gap": "26px",
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
});
export const MainImage = styled(motion.div).withConfig({
  displayName: "HomePopupstyle__MainImage",
  componentId: "sc-n0f1w2-2"
})(["", " background-color:rgba(255,255,255,0.7);.default-image{", " background-image:url('/images/default/tips-and-tricks.jpg');background-size:100%;background-position:center;}img,iframe,video{", "}a{", "}"], {
  "height": "100%",
  "width": "100%",
  "overflow": "hidden",
  "borderRadius": "1.5rem",
  "padding": "0.75rem"
}, {
  "height": "100%",
  "width": "100%",
  "overflow": "hidden",
  "borderRadius": "1rem"
}, {
  "aspectRatio": "16 / 9",
  "height": "100%",
  "width": "100%",
  "borderRadius": "1rem",
  "objectFit": "cover"
}, {
  "cursor": "pointer"
});
export const CloseImage = styled(motion.div).withConfig({
  displayName: "HomePopupstyle__CloseImage",
  componentId: "sc-n0f1w2-3"
})(["", " img{", "}@media (max-width:", "){", "}"], {
  "position": "absolute",
  "right": "-3rem",
  "top": "0px",
  "zIndex": "48",
  "cursor": "pointer"
}, {
  "aspectRatio": "1 / 1",
  "width": "2rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "position": "relative",
  "right": "0px"
});
export const MirrorContainer = styled.div.withConfig({
  displayName: "HomePopupstyle__MirrorContainer",
  componentId: "sc-n0f1w2-4"
})(["", " background-color:rgba(0,0,0,0.6);"], {
  "position": "fixed",
  "left": "0px",
  "top": "0px",
  "zIndex": "47",
  "height": "100%",
  "width": "100%",
  "cursor": "pointer"
});