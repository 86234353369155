import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "RecommendProductstyle__Container",
  componentId: "sc-1n68f50-0"
})(["", " max-width:", ";"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "width": "100%"
}, ({
  theme
}) => `${theme.layout.width}`);
export const ProductGridWrapper = styled.div.withConfig({
  displayName: "RecommendProductstyle__ProductGridWrapper",
  componentId: "sc-1n68f50-1"
})(["", " @media (max-width:", "){", " grid-template-columns:unset;grid-auto-columns:calc(100% / 3 - 4px);}@media (max-width:", "){grid-auto-columns:calc(100% / 2 - 4px);}"], {
  "marginTop": "1.5rem",
  "display": "grid",
  "gridTemplateColumns": "repeat(4, minmax(0, 1fr))",
  "gap": "2rem"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "gridAutoFlow": "column",
  "gap": "0.5rem",
  "overflowX": "scroll",
  "paddingLeft": "1rem",
  "paddingRight": "1rem",
  "paddingBottom": "1.25rem"
}, ({
  theme
}) => theme.breakpoint.md);