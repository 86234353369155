import { FC } from 'react';
import Image from 'next/image';
import { ICpConnect } from '@modules/cpConnect/domain/models/cpConnect.model';
import { Container, Cover, Description, Logo, LogoContainer, LogoWrapper, Title, WrapperContent } from './CpConnectCard.style';
interface Props {
  item: ICpConnect;
  onClick?: () => void;
}
const CpConnectCard: FC<Props> = ({
  item,
  onClick
}: Props) => {
  return <Container href={item.linkoutUrl} target="_blank" onClick={onClick} aria-label={item.title}>
            <Cover>
                <Image src={item.coverImg || '/images/default/brand-cover.jpg'} alt="cover image" onError={({
        currentTarget
      }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/images/default/brand-cover.jpg';
      }} width={514} height={238} />
            </Cover>
            <LogoContainer>
                <LogoWrapper>
                    <Logo src={item.avatarImg || '/images/default/brand-logo.png'} alt="logo image" onError={({
          currentTarget
        }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = '/images/default/brand-logo.png';
        }} width={160} height={160} />
                </LogoWrapper>
            </LogoContainer>
            <WrapperContent>
                <Title>{item.title}</Title>
                <Description>{item.content}</Description>
            </WrapperContent>
        </Container>;
};
export default CpConnectCard;