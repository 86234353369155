import { IHomePopup } from '@modules/home/domain/models/popup.model';
import { PopupResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
export class HomePopupEntityMapper {
  public static toDomain(popupResponse: PopupResponse): IHomePopup {
    const {
      id,
      title,
      linkOutUrl,
      imageUrl,
      youtubeUrl,
      popupType
    } = popupResponse;
    return {
      id,
      name: title,
      linkoutUrl: getLinkoutPrefixHttps(linkOutUrl),
      imgUrl: imageUrl ?? '',
      youtubeUrl,
      type: popupType
    };
  }
}