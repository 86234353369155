import { ITrendsForYouRepository } from '@modules/home/application/ports/trendsForYou.repository.port';
import { TrendsForYouQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { TrendsForYouEntityMapper } from '../../outbound/ports/trendsForYou.mapper';
export class TrendsForYouRepositoryImpl implements ITrendsForYouRepository {
  async getTrendsForYou(): ITrendsForYouRepository.output {
    try {
      const {
        data
      } = await gqlQuery({
        query: TrendsForYouQueryDocument
      });
      return right(TrendsForYouEntityMapper.toDomain(data.getTrendsForYou));
    } catch (error: any) {
      return left(error);
    }
  }
}