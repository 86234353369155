import Link from 'next/link';
import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "FoodServicestyle__Container",
  componentId: "sc-1gh067r-0"
})(["", " @media (max-width:", "){", "}"], {
  "marginTop": "5rem",
  "display": "flex",
  "width": "100%"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "flexDirection": "column"
});
export const BgImage = styled(Link).withConfig({
  displayName: "FoodServicestyle__BgImage",
  componentId: "sc-1gh067r-1"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "height": "380px",
  "flexBasis": "50%",
  "flexDirection": "column",
  "alignItems": "center",
  "justifyContent": "center",
  "backgroundSize": "cover",
  "backgroundPosition": "center"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "height": "190px",
  "gap": "0.5rem",
  "paddingLeft": "1.5rem",
  "paddingRight": "1.5rem",
  "paddingTop": "2.5rem",
  "paddingBottom": "2.5rem"
});
export const WrapperLeft = styled(BgImage).withConfig({
  displayName: "FoodServicestyle__WrapperLeft",
  componentId: "sc-1gh067r-2"
})(["background-image:url('/images/home/foodservice/food-service-right.png');"]);
export const WrapperRight = styled(BgImage).withConfig({
  displayName: "FoodServicestyle__WrapperRight",
  componentId: "sc-1gh067r-3"
})(["background-image:url('/images/home/foodservice/food-service-left.png');> div:nth-child(2){", "}@media (max-width:", "){> div:nth-child(2){", "}}"], {
  "minWidth": "195px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "minWidth": "0px"
});
export const Title = styled.div.withConfig({
  displayName: "FoodServicestyle__Title",
  componentId: "sc-1gh067r-4"
})(["", " @media (max-width:", "){", "}"], {
  "textAlign": "center",
  "fontSize": "4.5rem",
  "lineHeight": "1",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "fontSize": "55px",
  "lineHeight": "60px"
});
export const TextDescription = styled.div.withConfig({
  displayName: "FoodServicestyle__TextDescription",
  componentId: "sc-1gh067r-5"
})(["", " span{", "}img{", "}@media (max-width:", "){span{", "}}"], {
  "display": "flex",
  "alignItems": "center",
  "gap": "0.5rem",
  "textAlign": "center",
  "--tw-text-opacity": "1",
  "color": "rgb(255 255 255 / var(--tw-text-opacity))"
}, {
  "width": "100%",
  "fontSize": "2.25rem",
  "lineHeight": "2.5rem",
  "fontWeight": "400"
}, {
  "aspectRatio": "1 / 1",
  "width": "22px"
}, ({
  theme
}) => theme.breakpoint.lg, {
  "fontSize": "1.875rem",
  "lineHeight": "34px"
});