import { ICpConnect } from '@modules/cpConnect/domain/models/cpConnect.model';
import { CpConnectResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
export class CpConnectEntityMapper {
  public static toDomain(cpConnect: CpConnectResponse): ICpConnect {
    return {
      coverImg: cpConnect.mainCoverUrl,
      avatarImg: cpConnect.imageLogoUrl,
      title: cpConnect.title,
      content: cpConnect.description,
      linkoutUrl: getLinkoutPrefixHttps(cpConnect.linkOutUrl)
    };
  }
}