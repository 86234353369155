import { match } from 'ts-pattern';
import { ITrend, ITrendSocials } from '@modules/home/domain/models/trendsForYou.model';
import { Socials, TrendsForYouResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
import { SocialsType } from '@shared/types/socials';
export class TrendsForYouEntityMapper {
  public static toDomain(trends: TrendsForYouResponse[]): ITrend[] {
    return trends.map(({
      id,
      hashtag,
      socials
    }) => {
      return {
        id,
        hashtag,
        socials: this.trendsForYouSocialsMapper(socials)
      };
    });
  }
  private static trendsForYouSocialsMapper(socials: Socials[]): ITrendSocials[] {
    const output = [];
    socials.forEach(({
      name,
      url
    }) => {
      if (url) {
        output.push({
          name: this.socialsNameMapper(name as SocialsType),
          logo: this.socialsLogoMapper(name as SocialsType),
          linkout: getLinkoutPrefixHttps(url)
        });
      }
    });
    return output;
  }
  private static socialsNameMapper(social: SocialsType): string {
    return match(social).with(SocialsType.facebookUrl, () => 'Facebook').with(SocialsType.instagramUrl, () => 'Instagram').with(SocialsType.tiktokUrl, () => 'TikTok').with(SocialsType.xTwitterUrl, () => 'X').with(SocialsType.youtube, () => 'YouTube').otherwise(() => '-');
  }
  private static socialsLogoMapper(social: SocialsType): string {
    //TODO: Change image path
    return match(social).with(SocialsType.facebookUrl, () => '/images/home/banner/social/facebook.png').with(SocialsType.instagramUrl, () => '/images/home/banner/social/instagram.png').with(SocialsType.tiktokUrl, () => '/images/home/banner/social/tiktok.png').with(SocialsType.xTwitterUrl, () => '/images/home/banner/social/x.png').with(SocialsType.youtube, () => '/images/home/banner/social/youtube.png').otherwise(() => '-');
  }
}