import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "TrendHotTikTokstyle__Container",
  componentId: "sc-tt6805-0"
})(["", " @media (max-width:", "){", "}"], {
  "display": "flex",
  "flexDirection": "column",
  "gap": "1rem",
  "paddingTop": "2.5rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "gap": "1.5rem"
});
export const TitleAndTab = styled.div.withConfig({
  displayName: "TrendHotTikTokstyle__TitleAndTab",
  componentId: "sc-tt6805-1"
})(["", " max-width:", ";"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "width": "100%"
}, ({
  theme
}) => `${theme.layout.width}`);
export const TabWrapper = styled.div.withConfig({
  displayName: "TrendHotTikTokstyle__TabWrapper",
  componentId: "sc-tt6805-2"
})(["", ""], {
  "marginTop": "1.5rem",
  "width": "100%"
});