import { IHomePopupRepository } from '@modules/home/application/ports/popup.repository.port';
import { GetHomePopupQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { HomePopupEntityMapper } from '../../outbound/ports/popup.mapper';
export class HomePopupRepositoryImpl implements IHomePopupRepository {
  async getPopup(): IHomePopupRepository.getPopupOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GetHomePopupQueryDocument
      });
      const result = data.getPopup;
      const output = result ? HomePopupEntityMapper.toDomain(result) : null;
      return right(output);
    } catch (err) {
      console.error('Error IHomePopupRepository.getPopupOutput ', err);
      return left(err);
    }
  }
}