import { IGetGameBannerUsecase } from '@modules/home/domain/ports/getGameBanner.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { IGameBannerRepository } from '../ports/gameBanner.repository.port';
export class GetGameBannerUsecase implements IGetGameBannerUsecase {
  constructor(private readonly gameBannerRepo: IGameBannerRepository) {}
  async handle(page: string): IGetGameBannerUsecase.getGameBannerOutput {
    const result = await this.gameBannerRepo.getGameBanner(page);
    const value = result.value;
    if (result.isLeft()) return left([]);
    return right(value);
  }
}