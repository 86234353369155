import { IGetHomePopupUsecase } from '@modules/home/domain/ports/getHomePopup.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { IHomePopupRepository } from '../ports/popup.repository.port';
export class GetHomePopupUsecase implements IGetHomePopupUsecase {
  constructor(private readonly homePopupRepo: IHomePopupRepository) {}
  async handle(): IGetHomePopupUsecase.getPopupOutput {
    const result = await this.homePopupRepo.getPopup();
    const value = result.value;
    if (result.isLeft()) return left(value);
    return right(value);
  }
}