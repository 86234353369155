import { IGetTrendsForYouUsecase } from '@modules/home/domain/ports/getTrendsForYou.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { ITrendsForYouRepository } from '../ports/trendsForYou.repository.port';
export class GetTrendsForYouUsecase implements IGetTrendsForYouUsecase {
  constructor(private readonly trendsForYouRepository: ITrendsForYouRepository) {}
  async handle(): IGetTrendsForYouUsecase.getTrendsForYouOutput {
    const result = await this.trendsForYouRepository.getTrendsForYou();
    const value = result.value;
    if (result.isLeft()) return left(value);
    return right(value);
  }
}