import { IHomeCampaign } from '@modules/home/domain/models/campaign.model';
import { CampaignResponse } from '@modules/libs/graphql/generated/graphql';
import { getLinkoutPrefixHttps } from '@shared/helpers/linkout.helper';
export class HomeCampaignEntityMapper {
  public static toDomain(campaign: CampaignResponse): IHomeCampaign {
    const {
      id,
      title,
      description,
      linkoutUrl,
      thumbUrl
    } = campaign;
    return {
      id,
      title,
      description,
      linkoutUrl: getLinkoutPrefixHttps(linkoutUrl),
      thumbUrl
    };
  }
}