import { FC } from 'react';
import { GetServerSidePropsContext, PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import HeadElement from '@providers/core/HeadElement.provider';
import HomeProvider from '@providers/home/Home.provider';
import { Layout } from '@components/layouts';
import { DynamicPageProps, getFeedSeo } from '@shared/helpers/getFeedSeo.helper';
const HomePage: FC<DynamicPageProps> = ({
  seo,
  url
}) => {
  return <Layout>
            <HeadElement seo={seo} url={url} />
            <HomeProvider />
        </Layout>;
};
export default HomePage;
export const getServerSideProps = async (context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>) => {
  const output = await getFeedSeo(context);
  return output;
};