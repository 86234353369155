import { create } from 'zustand';
import { GetTrendsForYouUsecase } from '@modules/home/application/usecases/getTrendsForYou.usecase';
import { ITrend } from '@modules/home/domain/models/trendsForYou.model';
import { TrendsForYouRepositoryImpl } from '../../inbound/repositories/trendsForYou.repository';
interface TrendsForYouState {
  trends: ITrend[];
  setTrends: () => void;
  isOpenTrendForyou: boolean;
  setIsOpenTrendForyou: (val: boolean) => void;
  activeTrends: ITrend | undefined;
  setActiveTrends: (val: ITrend | undefined) => void;
  resetTrendsPopoverState: () => void;
}
export const trendsForYouStore = create<TrendsForYouState>(set => ({
  trends: [],
  setTrends: async () => {
    try {
      const trendsForYouRepositoryImpl = new TrendsForYouRepositoryImpl();
      const getTrendsForYouUsecase = new GetTrendsForYouUsecase(trendsForYouRepositoryImpl);
      const result = await getTrendsForYouUsecase.handle();
      if (result.isRight()) {
        set(() => ({
          trends: result.value
        }));
      }
    } catch (error) {
      console.error('error setTrends ', error);
    }
  },
  isOpenTrendForyou: false,
  setIsOpenTrendForyou: (val: boolean) => set({
    isOpenTrendForyou: val
  }),
  activeTrends: undefined,
  setActiveTrends: (val: ITrend | undefined) => set({
    activeTrends: val
  }),
  resetTrendsPopoverState: () => {
    set(() => ({
      isOpenTrendForyou: false,
      activeTrends: undefined
    }));
  }
}));