import { IRecommendProductRepository } from '@modules/home/application/ports/recommendProduct.repository.port';
import { ProductResponse, RecommendProductQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { RecommendProductEntityMapper } from '../../outbound/ports/recommendProduct.mapper';
export class RecommendProductRepositoryImpl implements IRecommendProductRepository {
  async getRecommendProduct(): IRecommendProductRepository.getRecommendProductOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: RecommendProductQueryDocument
      });
      const output = data.getRecommendedProduct.map((recommendProduct: ProductResponse) => RecommendProductEntityMapper.toDomain(recommendProduct));
      return right(output);
    } catch (error: any) {
      return left(error);
    }
  }
}