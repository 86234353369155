import { IGetCpConnectListUsecase } from '@modules/cpConnect/domain/ports/getCpConnectList.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { ICpConnectRepository } from '../ports/cpConnect.repository.port';
export class GetCpConnectListUsecase implements IGetCpConnectListUsecase {
  constructor(private readonly cpConnectRepository: ICpConnectRepository) {}
  async handle(limit?: number): IGetCpConnectListUsecase.output {
    const result = await this.cpConnectRepository.getAllList(limit);
    const value = result.value;
    if (result.isLeft()) return left(value);
    return right(value);
  }
}