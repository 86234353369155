import { FC } from 'react';
import { ICpConnect } from '@modules/cpConnect/domain/models/cpConnect.model';
import { SeeAll } from '@components/seeAll';
import { Title } from '@components/title';
import { useUtag } from '@shared/utils/hooks/useUtag.utils';
import { CpConnectCard } from '..';
import { Container, WrapperCard } from './HomeCpConnect.style';
interface Props {
  itemList: ICpConnect[];
}
const HomeCpConnectLayout: FC<Props> = ({
  itemList
}: Props) => {
  const utag = useUtag();
  const onClickConnectCard = (title: string, position: number) => {
    utag.link({
      tealium_event: 'cp_connect_click',
      position,
      cp_connect_name: title
    });
  };
  return <Container>
            <Title text="ซีพี คอนเนค 🎪" subText="เชื่อมต่อเว็บไซต์ในเครือซีพี" extra={<SeeAll url="/cp-connect" onClick={() => {
      utag.link({
        tealium_event: 'cp_connect_see_all_click'
      });
    }} />} />
            <WrapperCard>
                {itemList.length > 0 && itemList.map((item, index) => {
        return <CpConnectCard key={index} item={item} onClick={() => onClickConnectCard(item.title, index + 1)} />;
      })}
            </WrapperCard>
        </Container>;
};
export default HomeCpConnectLayout;