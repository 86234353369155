import styled from 'styled-components';
export const Container = styled.div.withConfig({
  displayName: "HomeCpConnectstyle__Container",
  componentId: "sc-1kbhvuk-0"
})(["", " max-width:", ";@media (max-width:", "){", "}"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "marginTop": "5rem",
  "display": "flex",
  "width": "100%",
  "flexDirection": "column",
  "gap": "1.5rem"
}, ({
  theme
}) => `${theme.layout.width}`, ({
  theme
}) => theme.breakpoint.md, {
  "gap": "2rem"
});
export const WrapperCard = styled.div.withConfig({
  displayName: "HomeCpConnectstyle__WrapperCard",
  componentId: "sc-1kbhvuk-1"
})(["", " @media (max-width:", "){", "}@media (max-width:", "){", "}"], {
  "display": "grid",
  "gridTemplateColumns": "repeat(4, minmax(0, 1fr))",
  "gap": "2rem"
}, ({
  theme
}) => theme.breakpoint.xl, {
  "paddingLeft": "1rem",
  "paddingRight": "1rem"
}, ({
  theme
}) => theme.breakpoint.md, {
  "gridTemplateColumns": "repeat(2, minmax(0, 1fr))",
  "columnGap": "9px",
  "rowGap": "0.75rem"
});