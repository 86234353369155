import { isEmpty } from 'radash';
import { IHomeCampaignRepository } from '@modules/home/application/ports/campaign.repository.port';
import { HomeCampaignQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { HomeCampaignEntityMapper } from '../../outbound/ports/campaign.mapper';
export class HomeCampaignRepositoryImpl implements IHomeCampaignRepository {
  async getCampaign(): IHomeCampaignRepository.getCampaignOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: HomeCampaignQueryDocument
      });
      const result = data.getLatestCampaign;
      const output = isEmpty(result) ? null : HomeCampaignEntityMapper.toDomain(result);
      return right(output);
    } catch (err) {
      console.error('Error IHomeCampaignRepository.getCampaignOutput ', err);
      return left(err);
    }
  }
}