import { FC, Fragment } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import Image from 'next/image';
import { useAnimationControls } from 'framer-motion';
import { isEmpty } from 'radash';
import { IHomePopup } from '@modules/home/domain/models/popup.model';
import { getYoutubeIdFromUrl } from '@shared/helpers/getYoutubeId.helper';
import { PopupType } from '@shared/types/popup.type';
import { useWindowSize } from '@shared/utils/hooks';
import breakpoint from '../../../shared/assets/breakpoint.json';
import { CloseImage, Container, MainImage, MirrorContainer, WrapperImage } from './HomePopup.style';
interface Props {
  popupItem: IHomePopup;
  isPopupOpen: boolean;
  setIsPopupOpen: (val: boolean) => void;
}
const HomePopup: FC<Props> = ({
  popupItem,
  isPopupOpen,
  setIsPopupOpen
}: Props) => {
  const {
    width
  } = useWindowSize();
  const isMobile = width < breakpoint.md;
  const containerAnim = useAnimationControls();
  const imageAnim = useAnimationControls();
  const closeButtonAnim = useAnimationControls();
  const desktopAnimation = async () => {
    await closeButtonAnim.start({
      opacity: 0
    }, {
      duration: 0.2,
      type: 'tween'
    });
    await imageAnim.start({
      height: '40px',
      width: '60%',
      opacity: 1
    }, {
      duration: 0.3,
      type: 'tween'
    });
    await imageAnim.start({
      height: '40px',
      width: '0',
      opacity: 0
    }, {
      duration: 0.3,
      type: 'tween'
    });
    await containerAnim.start({
      opacity: 0
    });
    return await containerAnim.start({
      display: 'none'
    });
  };
  const onClose = async () => {
    if (!isMobile) await desktopAnimation();
    setIsPopupOpen(false);
  };
  const parameters: YouTubeProps['opts'] = {
    height: '100%',
    width: '100%',
    playerVars: {
      playsinline: 1,
      modestbranding: 1,
      controls: 1
    }
  };
  const onPlayerReady = player => {
    player.mute();
    player.playVideo();
  };
  const renderImage = () => {
    return isEmpty(popupItem.linkoutUrl) ? <Image src={popupItem.imgUrl || '/images/default/tips-and-tricks.jpg'} alt={`CP brandsite popup`} onError={({
      currentTarget
    }) => {
      currentTarget.onerror = null; // prevents looping
      currentTarget.src = '/images/default/tips-and-tricks.jpg';
    }} width={706} height={397} priority /> : <a href={popupItem.linkoutUrl} target="__blank" aria-label="CP brandsite popup">
                <Image src={popupItem.imgUrl || '/images/default/tips-and-tricks.jpg'} alt={`CP brandsite popup`} onError={({
        currentTarget
      }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = '/images/default/tips-and-tricks.jpg';
      }} width={706} height={397} priority />
            </a>;
  };
  const renderYoutube = () => {
    return <Fragment>
                {isPopupOpen && <YouTube iframeClassName="iframe-youtube" videoId={`${getYoutubeIdFromUrl(popupItem.youtubeUrl)}`} opts={parameters} onReady={e => onPlayerReady(e.target)} onEnd={e => e.target.playVideo()} loading="lazy" />}
            </Fragment>;
  };
  return isPopupOpen && <Container animate={containerAnim}>
                <MirrorContainer onClick={onClose} />
                <WrapperImage>
                    <MainImage animate={imageAnim}>
                        <div className="default-image">
                            {popupItem.type === PopupType.Youtube ? renderYoutube() : renderImage()}
                        </div>
                    </MainImage>
                    <CloseImage onClick={onClose} animate={closeButtonAnim}>
                        <Image src="/icons/circle-cross.svg" alt="close button" width={32} height={32} />
                    </CloseImage>
                </WrapperImage>
            </Container>;
};
export default HomePopup;