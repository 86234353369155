import { Skeleton } from 'antd';
import styled from 'styled-components';
export const CustomSkeleton = styled(Skeleton.Image).withConfig({
  displayName: "GameBannerstyle__CustomSkeleton",
  componentId: "sc-8vgodu-0"
})(["", " height:calc(100vh - 72px) !important;svg{", "}@media (max-width:", "){height:calc(100vh - 56px) !important;}"], {
  "marginLeft": "auto",
  "marginRight": "auto",
  "width": "100% !important"
}, {
  "display": "none"
}, ({
  theme
}) => theme.breakpoint.md);