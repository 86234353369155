import { IGetRecommendProductUsecase } from '@modules/home/domain/ports/getRecommendProduct.usecase.port';
import { left, right } from '@shared/utils/either.utils';
import { shuffleArrayWithLimitOutput } from '@shared/utils/random.utils';
import { IRecommendProductRepository } from '../ports/recommendProduct.repository.port';
export class GetRecommendProductUsecase implements IGetRecommendProductUsecase {
  constructor(private readonly recommendProductRepository: IRecommendProductRepository) {}
  async handle(): IGetRecommendProductUsecase.getRecommendProductOutput {
    const result = await this.recommendProductRepository.getRecommendProduct();
    const value = result.value;
    if (result.isLeft()) return left(value);
    const output = shuffleArrayWithLimitOutput(value, 8);
    return right(output);
  }
}