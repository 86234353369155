import { IGameBannerRepository } from '@modules/home/application/ports/gameBanner.repository.port';
import { GameBannerQueryDocument } from '@modules/libs/graphql/generated/graphql';
import { gqlQuery } from '@modules/libs/graphql/gql-config';
import { left, right } from '@shared/utils/either.utils';
import { GameBannerEntityMapper } from '../../outbound/ports/gameBanner.mapper';
export class GameBannerRepositoryImpl implements IGameBannerRepository {
  async getGameBanner(page: string): IGameBannerRepository.getGameBannerOutput {
    try {
      const {
        data
      } = await gqlQuery({
        query: GameBannerQueryDocument,
        variables: {
          where: {
            page
          }
        }
      });
      if (data.getBannerByPage.highlight.length === 0) {
        return left('No data found');
      }
      const result = data.getBannerByPage.highlight[0];
      const output = GameBannerEntityMapper.toDomain(result);
      return right(output);
    } catch (error) {
      console.error('Error IGameBannerRepository.getGameBannerOutput', error);
      return left(error);
    }
  }
}